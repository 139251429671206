.keen-slider:not([data-keen-slider-disabled]){-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent;align-content:flex-start;display:flex;overflow:hidden;position:relative;touch-action:pan-y;-webkit-user-select:none;-moz-user-select:none;user-select:none;-khtml-user-select:none;width:100%}.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide{min-height:100%;overflow:hidden;position:relative;width:100%}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse]{flex-direction:row-reverse}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v]{flex-wrap:wrap}
/* latin-ext */
@font-face {
  font-family: '__Figtree_7d5794';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_7d5794';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Figtree_Fallback_7d5794';src: local("Arial");ascent-override: 93.60%;descent-override: 24.63%;line-gap-override: 0.00%;size-adjust: 101.49%
}.__className_7d5794 {font-family: '__Figtree_7d5794', '__Figtree_Fallback_7d5794';font-style: normal
}.__variable_7d5794 {--font-family-figtree: '__Figtree_7d5794', '__Figtree_Fallback_7d5794'
}

@font-face {
font-family: '__woodland_b43b17';
src: url(/_next/static/media/3a6ca9c2be685643-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__woodland_Fallback_b43b17';src: local("Arial");ascent-override: 65.50%;descent-override: 27.29%;line-gap-override: 0.00%;size-adjust: 109.92%
}.__className_b43b17 {font-family: '__woodland_b43b17', '__woodland_Fallback_b43b17';font-weight: 400
}

